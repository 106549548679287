import React, { useCallback, useMemo } from 'react';
import { Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { FormHelpers } from 'ontraccr-common';

import HoverHelp from '../../../common/HoverHelp';
import ConditionalRenderingBuilder from './ConditionalRenderingBuilder';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import { PlusOutlined } from '@ant-design/icons';

export default function ConditionalRenderingRow({
  onChange,
  onFormulaChange,
  hasConditionalRendering,
  conditionalRenderingFormula,
  sections = [],
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
  showCheckbox = true,
  isSection = false,
}) {
  const type = isSection ? 'section' : 'field';

  const formulas = useMemo(() => {
    if (Array.isArray(conditionalRenderingFormula)) return conditionalRenderingFormula;
    if (conditionalRenderingFormula) return [conditionalRenderingFormula];
    return [{}];
  }, [conditionalRenderingFormula]);

  const onInvidualFormulaChange = useCallback((index) => (newFormula) => {
    const newFormulas = [...formulas];
    newFormulas[index] = newFormula;
    onFormulaChange(newFormulas);
  }, [formulas, onFormulaChange]);

  const shouldShowAddButton = useCallback((index) => {
    if (index !== formulas.length - 1) return false;
    // Only show the add button if the current formula is complete
    return FormHelpers.isIndividualConditionalRenderingFormulaComplete(formulas[index]);
  }, [formulas]);

  const onAddClicked = useCallback(() => {
    onFormulaChange([...formulas, {}]);
  }, [formulas, onFormulaChange]);

  const onDeleteClicked = useCallback((index) => () => {
    const newFormulas = [...formulas];
    newFormulas.splice(index, 1);
    onFormulaChange(newFormulas);
  }, [formulas, onFormulaChange]);

  const relevantSections = useMemo(() => (
    isSection
      ? sections.filter((section) => section.id !== id)
      : sections
  ), [sections, id, isSection]);

  return (
    <>
      { showCheckbox && (
        <Row>
          <Col>
            <Checkbox
              onChange={onChange}
              checked={hasConditionalRendering}
            >
              Show this {type} conditionally
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp placement="topRight" content={`Allows this ${type} to be shown based off another value`} />
          </Col>
        </Row>
      )}
      { hasConditionalRendering && (
        <>
          {formulas.map((formula, index) => (
            <ConditionalRenderingBuilder
              id={id}
              sections={relevantSections}
              onChange={onInvidualFormulaChange(index)}
              conditionalRenderingFormula={formula}
              customers={customers}
              projects={projects}
              users={users}
              costcodes={costcodes}
              phases={phases}
              projectIdMap={projectIdMap}
              vendors={vendors}
              equipment={equipment}
              formTemplates={formTemplates}
              labels={labels}
              contactAddressBooks={contactAddressBooks}
              buckets={buckets}
              showDelete={formulas.length > 1}
              showAdd={shouldShowAddButton(index)}
              onAddClicked={onAddClicked}
              onDeleteClicked={onDeleteClicked(index)}
              isSection={isSection}
            />
          ))}
          { !formulas.length && (
            <OnTraccrButton
              type="primary"
              style={{ marginTop: 15 }}
              icon={<PlusOutlined />}
              title="Add Condition"
              onClick={onAddClicked}
            />
          )}
        </>
      )}
    </>
  );
}

ConditionalRenderingRow.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFormulaChange: PropTypes.func.isRequired,
  hasConditionalRendering: PropTypes.bool.isRequired,
  conditionalRenderingFormula: PropTypes.oneOf([
    PropTypes.shape({
      field: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
    })),
  ]),
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  id: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  costcodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  phases: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projectIdMap: PropTypes.shape({}),
  vendors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  equipment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  formTemplates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  contactAddressBooks: PropTypes.shape({}),
  buckets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  showCheckbox: PropTypes.bool,
  isSection: PropTypes.bool,
};

ConditionalRenderingRow.defaultProps = {
  sections: [],
  id: null,
  conditionalRenderingFormula: null,
  customers: [],
  projects: [],
  users: [],
  costcodes: [],
  phases: [],
  projectIdMap: {},
  vendors: [],
  equipment: [],
  formTemplates: [],
  labels: [],
  contactAddressBooks: {},
  buckets: [],
  showCheckbox: true,
  isSection: false,
};
