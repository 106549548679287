export default {};

export const HOUR_HEIGHT = 100;
export const INITIAL_SCALE = 0.5;
export const QUARTER_HEIGHT = HOUR_HEIGHT / 4;
export const RIGHT_OFFSET = 10;
export const ITEM_GAP = 3;
export const DAILY_LEFT_OFFSET = 60;
export const MONTHLY_ENTRY_HEIGHT = 20;
export const MONTHLY_HEADER_HEIGHT = 30;
export const MONTHLY_ENTRY_PADDING = 20;

export const BIWEEKLY_ROW_HEIGHT = 100;

export const BOTTOM_BUFFER = 5;

export const WEEKLY_BASE_OFFSET = 150;
export const WEEKLY_HEADER_BASE_HEIGHT = 50;
export const WEEKLY_HEADER_BLOCK_HEIGHT = 20;
export const WEEKLY_HEADER_PADDING = 2.5;
export const WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT = (
  WEEKLY_HEADER_BLOCK_HEIGHT + (2 * WEEKLY_HEADER_PADDING)
);

export const SCHEDULE_DATE_PICKER_TREE_OPTS = [
  {
    value: 'Day',
    title: 'Day',
  },
  {
    value: 'Week',
    title: 'Week',
  },
  {
    value: 'Month',
    title: 'Month',
  },
  {
    value: 'User',
    title: 'User',
    selectable: false,
    children: [
      {
        value: 'UserDay',
        title: 'Day',
      },
      {
        value: 'UserBiWeekly',
        title: 'Biweekly',
      },
    ],
  },
];

export const FILTER_VIEW_FIELDS = [
  {
    field: 'title',
    defaultValue: '',
  },
  {
    field: 'divisions',
    defaultValue: [],
  },
  {
    field: 'projects',
    defaultValue: [],
  },
  {
    field: 'phases',
    defaultValue: [],
  },
  {
    field: 'costcodes',
    defaultValue: [],
  },
  {
    field: 'users',
    defaultValue: [],
  },
  {
    field: 'description',
    defaultValue: '',
  },
  {
    field: 'forms',
    defaultValue: [],
  },
  {
    field: 'eventType',
    defaultValue: null,
  },
  {
    field: 'projectTypes',
    defaultValue: [],
  },
];
