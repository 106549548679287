import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Select, Table,
} from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import FormColorPicker from '../common/inputs/FormColorPicker';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormTextInput from '../common/inputs/FormTextInput';

import BoardMoveWorkflowConfigure from './BoardMoveWorkflowConfigure';

import {
  getTypes,
  getTypeMap,
  TITLE_FIELD,
  TITLE_FIELD_ID,
  SUB_TYPE_MAP,
} from './boardWorkflowConfig';
import BoardWorkflowSubtasks from './BoardWorkflowSubtasks';
import DurationInput from '../common/inputs/DurationInput';
import UserAssignmentSelector from '../forms/FormWorkflows/selectors/UserAssignmentSelector';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

// eslint-disable-next-line no-useless-escape
const emailRegex = new RegExp(/^[^@\s]+@[^@\s\.]+(?:\.[^@\.\s]+)+$/);

const tokenSeparators = [',', '\n'];

function BoardWorkflowAddDrawer({
  visible,
  onClose,
  onSubmit,
  cardTypeId,
  selectedWorkflow,
  currentStatuses = [],
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const stateProjectTypes = useSelector((state) => state.projects.projectTypes);

  const [name, setName] = useState();
  const [selectedType, setSelectedType] = useState();
  const [mappings, setMappings] = useState({});
  const [initialStatusId, setInitialStatusId] = useState();
  const [targetBoardId, setTargetBoardId] = useState();
  const [keepColor, setKeepColor] = useState(false);
  const [subTypes, setSubTypes] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState();
  const [duration, setDuration] = useState();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState([]);

  const users = useSelector((state) => state.users.users);

  const userIds = useMemo(() => {
    const idSet = new Set();
    users.forEach((user) => {
      if (user.active) {
        idSet.add(user.id);
      }
    });
    return idSet;
  }, [users]);

  const positionNames = useSelector((state) => state.settings.positionNames);

  const positionIds = useMemo(() => {
    const idSet = new Set();
    positionNames.forEach((position) => {
      idSet.add(position.id);
    });
    return idSet;
  }, [positionNames]);

  const validateAndFixEmails = useCallback((selectedValues) => (
    selectedValues?.filter((emailOrId) => (
      userIds.has(emailOrId)
      || positionIds.has(emailOrId)
      || emailRegex.test(emailOrId)
    ))
  ), [userIds, positionIds]);

  const onValuesChanged = useCallback((_, fullData) => {
    const {
      type: newType,
      name: newName,
      initialStatusId: newStatusId,
      targetBoardId: newTargetBoardId,
      keepColor: newKeepColor,
      subType: newSubType,
      duration: newDuration,
      emails: newEmails = [],
      projectTypes: newProjectTypes,
    } = fullData;

    setSelectedEmails(validateAndFixEmails(newEmails));

    const relevantSubTypes = SUB_TYPE_MAP[newType] ?? [];
    setSubTypes(relevantSubTypes);

    if (!relevantSubTypes.length) {
      setSelectedSubType();
    }

    setDuration(newDuration);
    setSelectedType(newType);
    setSelectedSubType(newSubType);
    setName(newName);
    setInitialStatusId(newStatusId);
    setKeepColor(newKeepColor);
    if (newTargetBoardId !== targetBoardId) {
      const newData = { ...fullData, targetStatusId: null };
      form.setFieldsValue(newData);
    }
    setTargetBoardId(newTargetBoardId);
    if (newType !== selectedType) {
      setMappings({});
      form.setFieldsValue({ mappings: {} });
    }
    setSelectedProjectTypes(newProjectTypes);
  }, [selectedType, form, targetBoardId, validateAndFixEmails]);

  const onSubmitClicked = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const newData = {
        mappings,
        type: selectedType,
        name,
        initialStatusId,
        color: values.color,
        subType: selectedSubType,
        duration,
        emails: selectedEmails,
      };
      if (selectedType === 'project' || selectedType === 'projectTypeChange') {
        newData.projectTypes = selectedProjectTypes;
      }
      if (selectedType === 'moveBoard') {
        newData.mappings = {
          targetBoardId: values.targetBoardId,
          targetStatusId: values.targetStatusId,
        };
        newData.keepColor = values.keepColor;
        newData.createShadow = !!values.createShadow;
      }
      if (!selectedWorkflow) newData.id = DateTime.local().toMillis();
      if (selectedType === 'statusTasks' || (selectedType === 'statusTime' && selectedSubType === 'createTask')) {
        newData.tasks = values.tasks;
      }

      onSubmit(newData);
    } catch (err) {
      //
    }
  }, [
    form,
    mappings,
    selectedType,
    name,
    selectedWorkflow,
    initialStatusId,
    duration,
    selectedSubType,
    selectedEmails,
    selectedProjectTypes,
  ]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setSelectedType();
      setMappings({});
      setName();
      setInitialStatusId();
      setTargetBoardId();
      setKeepColor(false);
      setDuration();
      setSubTypes([]);
      setSelectedSubType();
      setSelectedEmails([]);
      setSelectedProjectTypes([]);
    }
  }, [visible, form]);

  useEffect(() => {
    if (!selectedWorkflow) return;
    const {
      type: newType,
      name: newName,
      mappings: newMappings,
      initialStatusId: newStatusId,
      keepColor: newKeepColor,
      emails: newEmails,
      subType: newSubType,
      duration: newDuration,
      projectType: newProjectTypes,
      createShadow: newCreateShadow,
    } = selectedWorkflow;
    setName(newName);
    setSelectedType(newType);
    setMappings(newMappings);
    setInitialStatusId(newStatusId);
    setSelectedProjectTypes(newProjectTypes);

    const formData = {
      ...selectedWorkflow,
      color: selectedWorkflow.color || '#FFFFFFFF',
      tasks: selectedWorkflow.tasks || [],
      keepColor: !!selectedWorkflow.keepColor,
      emails: [],
    };
    if (newType === 'moveBoard') {
      formData.targetBoardId = (newMappings ?? {}).targetBoardId;
      formData.targetStatusId = (newMappings ?? {}).targetStatusId;
      formData.createShadow = !!newCreateShadow;
      setTargetBoardId(formData.targetBoardId);
      setKeepColor(newKeepColor);
    }

    if (newType === 'statusTime') {
      formData.duration = newDuration;
      setDuration(newDuration);
      setSubTypes(SUB_TYPE_MAP[newType] ?? []);
      setSelectedSubType(newSubType);

      if (newSubType === 'sendEmail') {
        formData.emails = newEmails;
        setSelectedEmails(newEmails);
      }
    }

    form.setFieldsValue(formData);
  }, [selectedWorkflow, form]);

  const ourFields = useMemo(() => {
    const {
      [cardTypeId]: {
        fields: sections = [],
      } = {},
    } = cardTemplates;
    const formFields = sections.map((section) => {
      const { fields = [] } = section;
      return fields;
    }).flat();
    return [TITLE_FIELD].concat(formFields);
  }, [cardTemplates, cardTypeId]);

  const TYPE_MAP = getTypeMap(t);

  const typeFields = useMemo(() => {
    const {
      [selectedType]: fields = [],
    } = TYPE_MAP;
    return fields;
  }, [selectedType]);

  const columns = useMemo(() => [
    {
      dataIndex: 'sourceField',
      title: 'Source Field',
      width: 300,
      render: (_, record) => {
        const { selectedType: fieldType, id, configProps: { dataType } = {} } = record;
        const options = typeFields.filter((field) => field.type === fieldType
          && (field.type !== 'dropdown' || dataType === field.dataType));
        const {
          [id]: value,
        } = mappings;
        return (
          <Select
            options={options}
            value={value}
            onSelect={(fieldKey) => {
              setMappings({
                ...mappings,
                [id]: fieldKey,
              });
            }}
          />
        );
      },
    }, {
      dataIndex: 'targetField',
      title: 'Card Field',
      width: 300,
      render: (_, record) => {
        const {
          configProps: { title } = {},
          id,
        } = record;
        const className = id === TITLE_FIELD_ID ? 'form-required-field' : null;
        return <span className={className}>{title}</span>;
      },
    },
  ], [ourFields, typeFields, mappings]);

  const canSubmit = useMemo(() => {
    if (!name || !selectedType) return false;
    if (selectedType === 'moveBoard') return !!targetBoardId;
    if (selectedType === 'statusTasks') return !!initialStatusId;
    if (selectedType === 'statusTime') {
      if (!duration?.duration || !duration?.durationUnit || !selectedSubType || !initialStatusId) {
        return false;
      }

      if (selectedSubType === 'sendEmail') {
        return selectedEmails?.length;
      }

      return true;
    }
    return !!(initialStatusId && mappings.cardTitle);
  }, [
    mappings,
    name,
    selectedType,
    initialStatusId,
    targetBoardId,
    duration,
    selectedSubType,
    selectedEmails,
  ]);

  const statusOptions = useMemo(() => (
    currentStatuses.map((status) => ({ label: status.title, value: status.id }))
  ), [currentStatuses]);

  const statusLabel = selectedType === 'statusTasks' || selectedType === 'statusTime' ? 'Status' : 'Initial Status';
  const translatedTypes = getTypes(t);

  const projectTypeOptions = useMemo(() => (
    stateProjectTypes.map((type) => ({ label: type.name, value: type.id }))
  ), [stateProjectTypes]);

  const isProjectTypeChange = selectedType === 'projectTypeChange';

  useEffect(() => {
    // Update the duration field based on selectedType changes
    if (selectedType === 'statusTime') {
      form.setFieldsValue({
        duration: { duration: 1, durationUnit: 'hours' },
      });
    } else {
      form.setFieldsValue({ duration: undefined });
    }
  }, [selectedType, form]);

  return (
    <Drawer
      title="Add Workflow"
      visible={visible}
      width={700}
      onClose={onClose}
      bodyStyle={{ padding: '0px 24px' }}
    >
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChanged}
        style={{
          position: 'absolute', top: 53, bottom: 51, left: 24, right: 24, overflowY: 'auto',
        }}
        initialValues={{ color: '#FFFFFFFF', tasks: [] }}
      >
        <FormTextInput
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
          isNotDisplay
        />
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Type is required' }]}
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
        >
          <Select options={translatedTypes} />
        </Form.Item>
        { !!subTypes?.length && (
          <Form.Item
            name="subType"
            label="Sub-type"
            rules={[{ required: true, message: 'Sub-type is required' }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0 }}
          >
            <Select options={subTypes} />
          </Form.Item>
        )}
        {
          selectedType
            && (selectedType === 'project' || isProjectTypeChange)
            && (
            <Form.Item
              name="projectTypes"
              label={`${t('Project')} Types`}
              labelCol={formLabelStyle}
              style={{ marginBottom: 0 }}
              rules={[{ required: isProjectTypeChange, message: `${t('Project')} Types is required` }]}
            >
              <Select
                mode="multiple"
                placeholder={isProjectTypeChange ? 'Select types' : 'All'}
                options={[
                  { key: -1, value: null, label: 'None' },
                  ...projectTypeOptions,
                ]}
              />
            </Form.Item>
            )
        }
        {
          selectedType && selectedType !== 'moveBoard'
          && (
          <Form.Item
            name="initialStatusId"
            label={statusLabel}
            rules={[{ required: true, message: `${statusLabel} is required` }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0 }}
          >
            <Select options={statusOptions} />
          </Form.Item>
          )
        }
        {
          selectedType === 'statusTime'
          && (
          <Form.Item
            name="duration"
            label="Time in Status"
            rules={[{ required: true, message: 'Time in Status is required' }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0 }}
          >
            <DurationInput min={duration?.durationUnit === 'minutes' ? 15 : 1} />
          </Form.Item>
          )
        }
        {
          selectedType
          && selectedType !== 'moveBoard'
          && selectedType !== 'statusTasks'
          && (selectedType !== 'statusTime' || selectedSubType === 'changeCardColor')
          && (
          <Form.Item
            name="color"
            label="Initial Color"
            rules={[{ required: true, message: 'Initial Color is required' }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0, maxWidth: 150 }}
          >
            <FormColorPicker isNotDisplay />
          </Form.Item>
          )
        }
        {
          selectedType && selectedType !== 'moveBoard' && selectedType !== 'statusTasks' && selectedType !== 'statusTime'
          && (
          <Form.Item
            name="mappings"
            label="Mapping"
            rules={[{ required: true, message: 'Type is required' }]}
            style={{ marginTop: 15 }}
          >
            <Table
              dataSource={ourFields}
              columns={columns}
              pagination={false}
              scroll={{ y: 'calc(100vh - 200px)' }}
            />
          </Form.Item>
          )
        }
        {
          selectedType === 'moveBoard'
          && (
          <BoardMoveWorkflowConfigure
            currentStatuses={currentStatuses}
            targetBoardId={targetBoardId}
            cardTypeId={cardTypeId}
            keepColor={keepColor}
          />
          )
        }
        {
         (selectedType === 'statusTasks' || (selectedType === 'statusTime' && selectedSubType === 'createTask'))
          && (
            <Form.Item
              name="tasks"
              label="Tasks"
              style={{ marginTop: 15 }}
            >
              <BoardWorkflowSubtasks
                cardTypeId={cardTypeId}
              />
            </Form.Item>
          )
        }
        {
          selectedType === 'statusTime' && selectedSubType === 'sendEmail'
          && (
            <Form.Item
              name="emails"
              label="Emails"
              style={{ marginTop: 15 }}
            >
              <UserAssignmentSelector
                selected={selectedEmails}
                mode="tags"
                showPositions
                type="email"
                text="Select Recipient(s):"
                tokenSeparators={tokenSeparators}
              />
            </Form.Item>
          )
        }
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={canSubmit}
      />
    </Drawer>
  );
}

BoardWorkflowAddDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cardTypeId: PropTypes.number.isRequired,
  selectedWorkflow: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    mappings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    initialStatusId: PropTypes.number,
    color: PropTypes.string,
    keepColor: PropTypes.bool,
    createShadow: PropTypes.bool,
  }),
  currentStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
};

BoardWorkflowAddDrawer.defaultProps = {
  visible: false,
  selectedWorkflow: null,
  currentStatuses: [],
};

export default BoardWorkflowAddDrawer;
